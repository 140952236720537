<template>
	<main>
		<components-contact/>
	</main>
</template>
<script>
import ComponentsContact from "@/components/views/index/contact.vue"
export default {
	components:{ComponentsContact}
}
</script>
